import { useTranslation } from 'react-i18next';
import { ControlPosition, MarkerProps, useControl } from 'react-map-gl/mapbox';
import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';
import i18n from 'core/translation/i18next/i18next';

type GeocoderControlProps = Omit<GeocoderOptions, 'accessToken' | 'mapboxgl' | 'marker'> & {
    mapboxAccessToken: string;
    marker?: Omit<MarkerProps, 'longitude' | 'latitude'>;

    position: ControlPosition;

    onLoading?: (e: object) => void;
    onResults?: (e: object) => void;
    onResult?: (e: GeocoderSearchResult) => void;
    onError?: (e: object) => void;
};

export enum GeocoderSearchResultType {
    Place = 'place',
    Address = 'address',
}

export type GeocoderSearchResult = {
    type: 'Feature';
    // full address of the place
    place_name: string;
    place_type: [GeocoderSearchResultType.Address] | [GeocoderSearchResultType.Place];
    geometry: { type: string; coordinates: [longitude: number, latitude: number] };
    // searched text (mostly street)
    text: string;
    // building number
    address: string;
    // Please note, response may vary depending on what are you looking for. For example, when you are looking for the whole country, you can't expect to get the street.
    // The order of elements in the "context" array will be as follows only if the request type is an address
    context: { id: string; text: string; short_code?: string }[];
};

export const GeocoderPanel = (props: GeocoderControlProps) => {
    const { t } = useTranslation();

    useControl<MapboxGeocoder>(
        () => {
            const ctrl = new MapboxGeocoder({
                ...props,
                marker: false,
                accessToken: props.mapboxAccessToken,
                placeholder: t('onboarding.locationsInfoForm.findPlaceOnMap'),
                language: i18n.language,

                types: 'address, place',
            });

            props.onLoading && ctrl.on('loading', props.onLoading);

            ctrl.on('result', (evt: { result: GeocoderSearchResult }) => {
                props.onResult && props.onResult(evt.result);
            });
            props.onError && ctrl.on('error', props.onError);
            return ctrl;
        },
        {
            position: props.position,
        },
    );

    return null;
};
