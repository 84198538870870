import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';

import styles from './Typography.module.scss';

type TTypographyComponent = 'h2' | 'h3' | 'h4' | 'h6' | 'span' | 'p' | 'a' | 'label';

interface TypographyOwnProps {
    variant?: 'h2' | 'h3' | 'h4' | 'h5' | 'link' | 'base14' | 'base12' | 'base11';
    component?: TTypographyComponent;
    color?: 'black' | 'primary' | 'secondary' | 'white' | 'inherit' | 'error' | 'success' | 'warning' | 'placeholder';
    fontWeight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'inherit';
}

export type TTypographyProps<C extends TTypographyComponent = 'p'> = TypographyOwnProps &
    Omit<React.ComponentPropsWithoutRef<C>, keyof TypographyOwnProps> & {
        component?: C;
    };

type TTypography = <C extends TTypographyComponent = 'p'>(
    props: TTypographyProps<C> & { ref?: React.Ref<any> },
) => React.ReactElement | null;

const TypographyInner = <C extends TTypographyComponent = 'p'>(
    { variant = 'base14', component, color, fontWeight, className, children, ...props }: TTypographyProps<C>,
    ref: React.Ref<any>,
) => {
    const Component: React.ElementType = useMemo(() => {
        if (component) return component;
        switch (variant) {
            case 'h2':
                return 'h2';
            case 'h3':
                return 'h3';
            case 'h4':
                return 'h4';
            case 'h5':
                return 'h5';
            case 'link':
                return 'a';
            default:
                return 'p';
        }
    }, [component, variant]);

    return (
        <Component
            ref={ref}
            className={classNames(
                styles.typography,
                styles[`color-${color}`],
                styles[`variant-${variant}`],
                styles[`fontWeight-${fontWeight}`],
                className,
            )}
            {...(props as any)}
        >
            {children}
        </Component>
    );
};

export const Typography = forwardRef(TypographyInner) as TTypography;
