import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertFilledIcon } from 'assets/icons';
import classNames from 'classnames';
import { selectAuth } from 'core/auth/services/selectors';
import { useAppSelector } from 'hooks';
import { FormError } from 'modules/form/components/new/FormError';
import { FormField } from 'modules/form/components/new/FormField';
import { EInvoicePaymentStatus, InvoiceDetailSchema } from 'modules/invoicing';
import { Checkbox } from 'modules/ui/components/new/Checkbox';
import { Pill } from 'modules/ui/components/new/Pill';
import { Typography } from 'modules/ui/components/new/Typography';
import { formatPrice } from 'utils/formatPrice';
import { timestamp } from 'utils/formatTimestamp';

import styles from './PaidState.module.scss';

export interface IPaidStateProps {}

export const PaidState: React.FC<IPaidStateProps> = () => {
    const { t } = useTranslation();
    const { paidStateChangedBy, paymentConfirmationDate, paymentStatus, paidAmount, currency, paid } =
        useWatch<InvoiceDetailSchema>();
    const { setValue } = useFormContext<InvoiceDetailSchema>();
    const { user } = useAppSelector(selectAuth);

    const processPaidStateChangeForPaidInvoice = (newPaidValue: boolean) => {
        // We want to track which user changed the paid field to false
        if (newPaidValue === true) {
            setValue('paidStateChangedBy_id', null);
            setValue('paidStateChangedBy', null);
            return;
        }
        if (newPaidValue === false) {
            if (!user) return;
            setValue('paidStateChangedBy_id', user.id);
            setValue('paidStateChangedBy', {
                email: user.email,
                name: user.name,
                surname: user.surname,
                user_id: user.id,
            });
            return;
        }
        return;
    };

    const processPaidStateChangeForUnpaidInvoice = (newPaidValue: boolean) => {
        // We want to track which user changed the paid field to true
        if (newPaidValue === true) {
            if (!user) return;
            setValue('paidStateChangedBy_id', user.id);
            setValue('paidStateChangedBy', {
                email: user.email,
                name: user.name,
                surname: user.surname,
                user_id: user.id,
            });
            return;
        }
        if (newPaidValue === false) {
            setValue('paidStateChangedBy_id', null);
            setValue('paidStateChangedBy', null);
            return;
        }
    };

    const handlePaidStateChange = (newPaidValue: boolean) => {
        // strategy for PAID and OVERPAID status
        if (paymentStatus === EInvoicePaymentStatus.PAID || paymentStatus === EInvoicePaymentStatus.OVERPAID) {
            return processPaidStateChangeForPaidInvoice(newPaidValue);
        }
        // strategy for UNDERPAID and UNPAID status
        if (paymentStatus === EInvoicePaymentStatus.UNDERPAID || paymentStatus === EInvoicePaymentStatus.UNPAID) {
            return processPaidStateChangeForUnpaidInvoice(newPaidValue);
        }
    };

    const getPaidLabel = () => {
        if (paymentStatus === EInvoicePaymentStatus.UNDERPAID) {
            return t('invoicing.form.invoiceStatus.underPaid');
        }
        if (paymentStatus === EInvoicePaymentStatus.OVERPAID) {
            return t('invoicing.form.invoiceStatus.overPaid');
        }
        return t('invoicing.form.invoiceStatus.paid');
    };

    const getPaidSubLabel = useCallback(() => {
        if (paymentStatus === EInvoicePaymentStatus.PAID && paymentConfirmationDate) {
            return t('invoicing.form.invoiceStatus.confirmedByBank', {
                date: timestamp(paymentConfirmationDate)?.format('DD. MM. YYYY'),
            });
        }
        if (
            (paymentStatus === EInvoicePaymentStatus.UNDERPAID || paymentStatus === EInvoicePaymentStatus.OVERPAID) &&
            paymentConfirmationDate &&
            currency
        ) {
            return t('invoicing.form.invoiceStatus.amountReceivedByBank', {
                amount: formatPrice(Number(paidAmount), t, { currency: currency }),
                date: timestamp(paymentConfirmationDate)?.format('DD. MM. YYYY'),
            });
        }
        if (paymentStatus === EInvoicePaymentStatus.UNPAID && paid === true) {
            return t('invoicing.form.invoiceStatus.confirmedByUser');
        }
        return null;
    }, [currency, paid, paidAmount, paymentConfirmationDate, paymentStatus, t]);

    return (
        <Controller
            name="paid"
            render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
                <FormField errorSlot={error && <FormError>{error?.message}</FormError>}>
                    <label
                        className={classNames(styles.wrapper, {
                            [styles['wrapper-error']]: !!error,
                        })}
                    >
                        <Checkbox
                            error={!!error}
                            onCheckedChange={(value) => {
                                if (value === 'indeterminate') return;
                                onChange(value);
                                handlePaidStateChange(value);
                            }}
                            checked={value}
                            {...fieldProps}
                        />
                        <div className={styles.labelWrapper}>
                            <Typography>{getPaidLabel()}</Typography>
                            <Typography variant="base12" fontWeight="normal" color="secondary">
                                {getPaidSubLabel()}
                            </Typography>
                        </div>
                        <div className={styles.userWrapper}>
                            {(paymentStatus === EInvoicePaymentStatus.OVERPAID ||
                                paymentStatus === EInvoicePaymentStatus.UNDERPAID) &&
                                !paidStateChangedBy && (
                                    <Pill
                                        color="warning"
                                        colorMode="hard"
                                        size="medium"
                                        prefix={<AlertFilledIcon height={16} width={16} />}
                                    >
                                        <Typography variant="base14" fontWeight="medium" color="white">
                                            {t('invoicing.form.invoiceStatus.amountDoesNotMatch')}
                                        </Typography>
                                    </Pill>
                                )}
                            {paidStateChangedBy && (
                                <>
                                    <Pill color="primary" colorMode="hard" size="medium">
                                        <Typography variant="base14" fontWeight="medium" color="white">
                                            {t('invoicing.form.invoiceStatus.paidStateChangedBy', {
                                                name: `${paidStateChangedBy?.name} ${paidStateChangedBy?.surname}`,
                                            })}
                                        </Typography>
                                    </Pill>
                                </>
                            )}
                        </div>
                    </label>
                </FormField>
            )}
        />
    );
};
