import { resolveValue, toast, Toaster } from 'react-hot-toast';
import { CheckWhiteIcon } from 'assets/icons';
import { CrossIcon } from 'assets/icons';
import { ExclamationIcon } from 'assets/icons';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './Toast.module.scss';

export const Toast = () => (
    <Toaster position="bottom-right">
        {(t) => (
            <motion.div
                key={t.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: t.visible ? 1 : 0, y: t.visible ? 0 : 50 }}
                className={classNames(styles.toaster, {
                    [styles.success]: t.type === 'success',
                    [styles.error]: t.type === 'error',
                })}
            >
                <div className={styles.toaster_content}>
                    <div
                        className={classNames({
                            [styles.success_icon]: t.type === 'success',
                            [styles.error_icon]: t.type === 'error',
                        })}
                    >
                        {t.type === 'success' && <CheckWhiteIcon />}
                        {t.type === 'error' && <ExclamationIcon />}
                    </div>
                    {resolveValue(t.message, t)}
                </div>
                <button className={styles.close} onClick={() => toast.dismiss(t.id)}>
                    <CrossIcon />
                </button>
            </motion.div>
        )}
    </Toaster>
);
