import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedCheckboxField } from 'modules/form/components/new/ExtendedCheckboxField';

import { PaidState } from '../PaidState';

import styles from './InvoiceStates.module.scss';

export interface IInvoiceStatesProps {}

export const InvoiceStates: React.FC<IInvoiceStatesProps> = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.statesWrapper}>
            <ExtendedCheckboxField name="invoiceSent" label={t('invoicing.form.invoiceStatus.invoiceSent')} />
            <ExtendedCheckboxField name="exported" label={t('invoicing.form.invoiceStatus.exported')} />
            <ExtendedCheckboxField name="canceled" label={t('invoicing.form.invoiceStatus.canceled')} />
            <PaidState />
        </div>
    );
};
