import type { IconProps } from './types';

export const CrossIcon: React.FC<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth={1.5} {...props}>
            <rect id="bg" width="24" height="24" fill="none" />
            <line y1="8" x2="8" transform="translate(8 8)" fill="none" stroke="currentColor" />
            <line y1="8" x2="8" transform="translate(16 8) rotate(90)" fill="none" stroke="currentColor" />
        </svg>
    );
};
