import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Checkbox, ICheckboxProps } from 'modules/ui/components/new/Checkbox';
import { Typography } from 'modules/ui/components/new/Typography';

import styles from './ExtendedCheckbox.module.scss';

export interface IExtendedCheckboxProps extends Omit<ICheckboxProps, 'ref'> {
    label: string;
    subLabel?: string;
}

export const ExtendedCheckbox = forwardRef<HTMLButtonElement, IExtendedCheckboxProps>(
    ({ label, subLabel, id, error, ...checkboxProps }, ref) => {
        return (
            <label
                htmlFor={id}
                className={classNames(styles.wrapper, {
                    [styles.error]: !!error,
                })}
            >
                <Checkbox {...{ id, error }} {...checkboxProps} ref={ref} />
                <div className={styles.labelWrapper}>
                    <Typography>{label}</Typography>
                    {subLabel && (
                        <Typography variant="base12" color="secondary">
                            {subLabel}
                        </Typography>
                    )}
                </div>
            </label>
        );
    },
);
