import type { IconProps } from './types';

export const MinusIcon: React.FC<IconProps> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1.5}
            {...props}
        >
            <path d="M4.49219 12.0014H19.4922" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
