import type { IconProps } from './types';

export const AlertFilledIcon: React.FC<IconProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12C24 18.6275 18.6275 24 12 24C5.37258 24 0 18.6275 0 12C0 5.37258 5.37258 0 12 0C18.6275 0 24 5.37258 24 12ZM10.875 17.25V15.75H13.125V17.25H10.875ZM10.875 6V14.25H13.125V6H10.875Z"
                fill="currentColor"
            />
        </svg>
    );
};
