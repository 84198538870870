import React from 'react';
import { TTypographyProps, Typography } from 'modules/ui/components/new/Typography';

export interface IFormErrorProps extends Omit<TTypographyProps<'p'>, 'color'> {
    visible?: boolean;
}

export const FormError: React.FC<IFormErrorProps> = ({ children, visible = true, ...props }) => {
    if (!visible) {
        return null;
    }
    return (
        <Typography color="error" {...props}>
            {children}
        </Typography>
    );
};
