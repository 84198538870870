import type { IconProps } from './types';

export const FilterIcon: React.FC<IconProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} {...props}>
            <g>
                <path d="M3 6H21" stroke="currentColor" />
                <path d="M9 18H15" stroke="currentColor" />
                <path d="M18 12H6" stroke="currentColor" />
            </g>
        </svg>
    );
};
