import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl/mapbox';
import { GroupedDispatchersData } from 'modules/dispatcherSearch/types';

import { useDispatcherSearchMapContext } from '../DispatcherSearchMap/context/useDispatcherSearchMapContext';
import { MapPoint } from '../MapPoint';
import { MapPointToast } from '../MapPointToast';

import styles from './MapPoints.module.scss';

type MapPointsProps = {
    transformedGroupedDispatcherData: GroupedDispatchersData[] | undefined;
    mapZoomValue: number;
};

export const MapPoints: React.FC<MapPointsProps> = ({ transformedGroupedDispatcherData, mapZoomValue }) => {
    const { isDispatcherFiltrationToggled, setDispatcherSelectedFromMap } = useDispatcherSearchMapContext();

    const handleSelectionFromMap = (id: number) => {
        setDispatcherSelectedFromMap(id);
    };

    const filteredMarkers = useMemo(() => {
        if (isDispatcherFiltrationToggled || !transformedGroupedDispatcherData) return [];

        return transformedGroupedDispatcherData.filter(
            (transformedDispatcherItem) => transformedDispatcherItem.reason !== null,
        );
    }, [isDispatcherFiltrationToggled, transformedGroupedDispatcherData]);

    const filteredSecondCoordinatesMarkers = useMemo(() => {
        if (isDispatcherFiltrationToggled || !transformedGroupedDispatcherData) return [];

        return transformedGroupedDispatcherData.filter(
            (transformedDispatcherItem) =>
                transformedDispatcherItem.reason !== null && transformedDispatcherItem.reason.type !== 'hq',
        );
    }, [isDispatcherFiltrationToggled, transformedGroupedDispatcherData]);

    return (
        <>
            {filteredMarkers.map(({ reason, wholeDispatcherData }) => (
                <Marker
                    key={wholeDispatcherData[0].id}
                    latitude={reason?.locations[0].latitude ?? 0}
                    longitude={reason?.locations[0].longitude ?? 0}
                    pitchAlignment="viewport"
                    anchor="bottom"
                >
                    <div className={styles.mapPointContainer}>
                        {mapZoomValue >= 7 && (
                            <div className={styles.mapToast}>
                                <MapPointToast
                                    dispatchers={wholeDispatcherData}
                                    handleSelectionFromMap={handleSelectionFromMap}
                                />
                            </div>
                        )}
                        <MapPoint
                            onClick={() => {
                                if (wholeDispatcherData.length === 1) {
                                    setDispatcherSelectedFromMap(wholeDispatcherData[0].id);
                                }
                            }}
                            icon={reason?.type}
                        />
                    </div>
                </Marker>
            ))}

            {/* If reason is not "hq", display a marker on second coordinates */}
            {filteredSecondCoordinatesMarkers.map(({ reason, wholeDispatcherData }) => (
                <Marker
                    key={wholeDispatcherData[0].id}
                    latitude={reason?.locations[1].latitude ?? 0}
                    longitude={reason?.locations[1].longitude ?? 0}
                    pitchAlignment="viewport"
                    anchor="bottom"
                >
                    <div className={styles.mapPointContainer}>
                        {mapZoomValue >= 7 && (
                            <div className={styles.mapToast}>
                                <MapPointToast
                                    dispatchers={wholeDispatcherData}
                                    handleSelectionFromMap={handleSelectionFromMap}
                                />
                            </div>
                        )}
                        <MapPoint
                            icon={reason?.type}
                            onClick={() => {
                                if (wholeDispatcherData.length === 1) {
                                    setDispatcherSelectedFromMap(wholeDispatcherData[0].id);
                                }
                            }}
                        />
                    </div>
                </Marker>
            ))}
        </>
    );
};
