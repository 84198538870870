import type { IconProps } from './types';

export const CalendarIcon: React.FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            {...props}
        >
            <rect width="24" height="24" fill="none" />
            <rect width="18" height="16" rx="2" transform="translate(3 5)" fill="none" stroke="currentColor" />
            <path
                d="M3213.737-1185.637v-2"
                transform="translate(-3207.431 1190.637)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path
                d="M3213.737-1185.637v-2"
                transform="translate(-3195.948 1190.637)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <rect width="4.306" height="4.306" rx="1" transform="translate(6.306 8.411)" fill="currentColor" />
        </svg>
    );
};
