import type { IconProps } from './types';

export const SearchIcon: React.FC<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth={1.5} {...props}>
            <circle cx="7" cy="7" r="7" transform="translate(4.5 4.5)" fill="none" stroke="currentColor" />
            <line x1="3" y1="3" transform="translate(16.5 16.5)" fill="none" stroke="currentColor" />
            <rect id="Rectangle_146" data-name="Rectangle 146" width="24" height="24" fill="none" />
        </svg>
    );
};
