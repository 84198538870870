import 'mapbox-gl/dist/mapbox-gl.css';

import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl/mapbox';
import { LocationMarkIcon } from 'assets/icons';
import { config } from 'config';
import { fetchReverseLocationQuery } from 'core/api';
import mapboxgl from 'mapbox-gl';
import { CarrierPlaceSchema } from 'modules/carriers';
import { Spinner } from 'modules/ui';
import { formatLocationDataFromApi } from 'utils';

import { GeocoderPanel, GeocoderSearchResult } from '../GeocoderPanel';
import { IMapPreferenceModalProps, MapPreferenceModal } from '../MapPreferenceModal/MapPreferenceModal';

import styles from './OnboardingMap.module.scss';

interface IOnboardingMapProps {
    locationsData: CarrierPlaceSchema[];
    selectedLocationIdx: number | null;
    modalLocation: IMapPreferenceModalProps | null;
    savePlace: (place: CarrierPlaceSchema, index: number | null) => void;
    setSelectedLocationIdx: (index: number | null) => void;
    setModalLocation: (location: IMapPreferenceModalProps | null) => void;
}

export const OnboardingMap = ({
    locationsData,
    selectedLocationIdx,
    modalLocation,
    savePlace,
    setSelectedLocationIdx,
    setModalLocation,
}: IOnboardingMapProps) => {
    const [fetchingCoordinates, setFetchingCoordinates] = useState<[latitude: number, longitude: number] | null>(null);

    const { t } = useTranslation();

    const handleMapClick = async (e: mapboxgl.MapLayerMouseEvent) => {
        setFetchingCoordinates([e.lngLat.lat, e.lngLat.lng]);
        setSelectedLocationIdx(null);
        const result = await fetchReverseLocationQuery({ coordinates: [e.lngLat.lat, e.lngLat.lng] });

        setFetchingCoordinates(null);
        if (result)
            return setModalLocation({ ...result, directionLoading: false, directionDischarge: false, note: null });
        toast.error(t('onboarding.locationsInfoForm.failedToFindPlaceOnMap'));
    };

    const handleSearchLocation = (result: GeocoderSearchResult) => {
        setSelectedLocationIdx(null);
        setModalLocation(null);

        const transformedResult = {
            ...formatLocationDataFromApi(result),
            directionLoading: false,
            directionDischarge: false,
            note: null,
        };
        setModalLocation(transformedResult);
    };

    const onSavePlace = (place: Omit<CarrierPlaceSchema, 'dispatcher_id'>) => {
        savePlace({ ...place }, selectedLocationIdx);
        setSelectedLocationIdx(null);
        setModalLocation(null);
    };

    const onMarkerClick = (index: number) => {
        setSelectedLocationIdx(index);
        setModalLocation(locationsData[index]);
    };

    return (
        <div className={styles.container}>
            <Map
                id="map"
                mapLib={import('mapbox-gl')}
                mapboxAccessToken={config.api.mapboxToken}
                initialViewState={{
                    longitude: 14.81084786751657,
                    latitude: 48.82111444207089,
                    zoom: 4,
                }}
                onClick={handleMapClick}
                style={{ width: 'calc(100% + 32px)', height: '100%', overflow: 'hidden' }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
            >
                {locationsData.map((location, index) => (
                    <Marker
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            onMarkerClick(index);
                        }}
                        key={index}
                        latitude={location.latitude}
                        longitude={location.longitude}
                        anchor="bottom"
                    >
                        <LocationMarkIcon color={index === selectedLocationIdx ? '#243c53' : '#1770ff'} />
                    </Marker>
                ))}
                <GeocoderPanel
                    mapboxAccessToken={config.api.mapboxToken}
                    position="top-left"
                    onResult={handleSearchLocation}
                />
                {modalLocation && (
                    <Marker latitude={modalLocation.latitude} longitude={modalLocation.longitude} anchor="bottom">
                        <LocationMarkIcon />
                    </Marker>
                )}
                {fetchingCoordinates && (
                    <Marker longitude={fetchingCoordinates[1]} latitude={fetchingCoordinates[0]}>
                        <Spinner fullScreen />
                    </Marker>
                )}
            </Map>
            {modalLocation && <MapPreferenceModal savePlace={onSavePlace} {...modalLocation} />}
        </div>
    );
};
