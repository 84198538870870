import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './FormField.module.scss';

export interface IFormFieldProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    labelSlot?: React.ReactNode;
    errorSlot?: React.ReactNode;
}

export const FormField: React.FC<IFormFieldProps> = ({ labelSlot, errorSlot, className, children, ...props }) => {
    return (
        <div className={classNames(styles.wrapper, className)} {...props}>
            {labelSlot && <div className={styles.labelSlot}>{labelSlot}</div>}
            {children}
            {errorSlot && <div className={styles.errorSlot}>{errorSlot}</div>}
        </div>
    );
};
