import React from 'react';
import { Controller } from 'react-hook-form';
import { FormError } from 'modules/form/components/new/FormError';
import { FormField } from 'modules/form/components/new/FormField';
import { ExtendedCheckbox, IExtendedCheckboxProps } from 'modules/ui/components/new/ExtendedCheckbox';



export interface IExtendedCheckboxFieldProps extends IExtendedCheckboxProps {
    name: string;
}

export const ExtendedCheckboxField: React.FC<IExtendedCheckboxFieldProps> = ({ name, ...inputProps }) => {
    return (
        <Controller
            name={name}
            render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
                <FormField errorSlot={error && <FormError>{error?.message}</FormError>}>
                    <ExtendedCheckbox
                        error={!!error}
                        onCheckedChange={(value) => {
                            onChange(value);
                        }}
                        checked={value}
                        {...fieldProps}
                        {...inputProps}
                    />
                </FormField>
            )}
        />
    );
};
