import React from 'react';
import { Layer, Source } from 'react-map-gl/mapbox';
import { FeatureCollection, Position } from 'geojson';

import { convertMetersToPixelsAtMaxZoom } from '../../utils/convertMetersToPixelsAtMaxZoom';

type MapSelectionRadiusProps = {
    coordinates: Position;
    name: "loading" | "discharge";
    radius: number;
    loadingOrDischargeLatitude: number; //used for radius calculation
};

export const MapSelectionRadius: React.FC<MapSelectionRadiusProps> = ({
    coordinates,
    name,
    radius,
    loadingOrDischargeLatitude,
}) => {


    const RadiusGeoJson: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: { name },
                geometry: {
                    type: 'Point',
                    coordinates: coordinates,
                },
            },
        ],
    };

    return (

            <Source id={`${name}-circle-source`} type="geojson" data={RadiusGeoJson}>
                <Layer
                    id={name}
                    type="circle"
                    paint={{
                        'circle-radius': {
                            stops: [
                                [0, 0],
                                [
                                    20,
                                    convertMetersToPixelsAtMaxZoom(
                                        radius * 1000, // transform KM from the slider input, to meters for the map selection radius by multiplying
                                        loadingOrDischargeLatitude || 0,
                                    ),
                                ],
                            ],
                            base: 2,
                        },
                        'circle-color': '#1770ff',
                        'circle-opacity': 0.2,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#1770ff',
                        'circle-stroke-opacity': 0.8,
                        'circle-pitch-alignment': 'map',
                    }}
                />
            </Source>

    );
};
