import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'react-map-gl/mapbox';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { config } from 'config';
import { addDispatcher, updateDispatcher, useDocumentTitle } from 'core/application';
import { useAppDispatch, useQuery } from 'hooks';
import { carrierApi } from 'modules/carriers';
import { PageContent, Subheader } from 'modules/layout';
import { usePostNewDispatcherMutation } from 'modules/onboarding/services';
import { OnboardingSchema } from 'modules/onboarding/types';
import { transformOnboardingData } from 'modules/onboarding/utils';
import { BackButton, Button } from 'modules/ui';

import { DispatcherBasicForm } from '../DispatcherBasicForm';

import styles from './DispatcherNewPage.module.scss';

export const DispatcherNewPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.dispatchers'));
    const [searchParams] = useSearchParams();

    const query = useQuery();
    const dispatch = useAppDispatch();
    const carrier_id = query.get('carrier_id');
    const selectedDispatcherIndex = searchParams.get('selectedDispatcherIndex');

    const [postNewDispatcher, { isLoading: isSubmittingFormLoading }] = usePostNewDispatcherMutation();

    const prepareData = async (data: OnboardingSchema) => {
        // for dispatcher with no created carrier yet
        const pageReturnFunction = () => {
            const pageToReturn = searchParams.get('pageToReturn');
            if (pageToReturn) {
                const [pathname, search] = pageToReturn.split('?');
                navigate({
                    pathname: pathname,
                    search: search
                        ? `${search}&useCachedData=${carrier_id === 'new' ? 'true' : 'false'}`
                        : createSearchParams({ useCachedData: carrier_id === 'new' ? 'true' : 'false' }).toString(),
                });
                return;
            }
            navigate(`${config.routes.carriers.list}/${carrier_id}`);
        };

        if (carrier_id === 'new') {
            dispatch(
                // update a new dispatcher, stored in redux
                selectedDispatcherIndex
                    ? updateDispatcher({ dispatcher: data, index: Number(selectedDispatcherIndex) })
                    : // create a fully new dispatcher
                      addDispatcher(data),
            );

            pageReturnFunction();
            return;
        }
        const formatted = transformOnboardingData({ currentData: data });

        // if there is existing carrier for this dispatcher
        try {
            await postNewDispatcher({ data: formatted }).unwrap();
            dispatch(carrierApi.util.invalidateTags([{ type: 'CarrierItem', id: formatted.carrier_id }]));
            toast.success(t('onboarding.updated'));
            pageReturnFunction();
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="dispatcher-form"
                                type="submit"
                            >
                                {t('onboarding.saveDispatcher')}
                            </Button>
                        </div>
                    }
                    title={t('onboarding.newDispatcher')}
                />
            }
        >
            <MapProvider>
                <DispatcherBasicForm
                    viewSide="detail"
                    carrier_id={carrier_id ? Number(carrier_id) : undefined}
                    selectedDispatcherIndex={selectedDispatcherIndex ? Number(selectedDispatcherIndex) : undefined}
                    prepareData={prepareData}
                />
            </MapProvider>
        </PageContent>
    );
};
