import type { IconProps } from './types';

export const ChevronLeftIcon: React.FC<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth={1.5} {...props}>
            <rect width="24" height="24" transform="translate(24 0) rotate(90)" fill="none" />
            <path
                d="M0,0,.182,6.818,7,7"
                transform="translate(13.501 7.05) rotate(45)"
                fill="none"
                stroke="currentColor"
            />
        </svg>
    );
};
