import React, { forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Pill.module.scss';

export interface IPillProps
    extends Omit<React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'prefix'> {
    color?: 'primary' | 'success' | 'error' | 'warning' | 'gray';
    size?: 'small' | 'medium' | 'large';
    colorMode?: 'soft' | 'hard';
    rounded?: 'full' | 'normal';
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
}

export const Pill = forwardRef<HTMLDivElement, IPillProps>(
    (
        {
            colorMode = 'soft',
            color = 'primary',
            rounded = 'full',
            size = 'medium',
            className,
            children,
            prefix,
            suffix,
            ...props
        },
        ref,
    ) => {
        return (
            <div
                ref={ref}
                className={classNames(
                    styles.pill,
                    styles[`size-${size}`],
                    styles[`color-${color}`],
                    styles[`rounded-${rounded}`],
                    styles[`colorMode-${colorMode}`],
                    {
                        [styles.withPrefix]: !!prefix,
                        [styles.withSuffix]: !!suffix,
                    },
                    className,
                )}
                {...props}
            >
                {prefix}
                {children && <div className={styles.content}>{children}</div>}
                {suffix}
            </div>
        );
    },
);
