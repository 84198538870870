import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Layer, Map as MapboxMap, Marker, Source } from 'react-map-gl/mapbox';
import { config } from 'config';
import { fetchRoutesQuery } from 'core/api';
import { FeatureCollection } from 'geojson';
import { isNumber } from 'lodash';
import { CommissionSchema } from 'modules/commissions';
import {
    DispatcherSearchCounts,
    DispatcherSearchResponse,
    GroupedDispatchersData,
} from 'modules/dispatcherSearch/types';
import { Spinner, Typography } from 'modules/ui';

import { DispatcherSearchMapLegend } from '../DispatcherSearchMapLegend';
import { MapPoints } from '../MapPoints';
import { MapSelectionRadius } from '../MapSelectionRadius';
import { useDispatcherSearchMapContext } from './context/useDispatcherSearchMapContext';

import styles from './DispatcherSearchMap.module.scss';

type DispatcherSearchMapProps = {
    searchedCarriers: DispatcherSearchResponse | undefined;
};

export const DispatcherSearchMap: React.FC<DispatcherSearchMapProps> = ({ searchedCarriers }) => {
    const [routePoints, setRoutePoints] = useState<[latitude: number, longitude: number][]>([]);
    const [routeDistance, setRouteDistance] = useState<number>(0);
    const [transformedGroupedDispatcherData, setTransformedGroupedDispatcherData] =
        useState<GroupedDispatchersData[]>();
    const [transformedDispatcherCounts, setTransformedDispatcherCounts] = useState<DispatcherSearchCounts>();
    const [mapZoomValue, setMapZoomValue] = useState<number>(0);

    const { onLoadingRadiusMapChange, onDischargeRadiusMapChange, isDispatcherFiltrationToggled, isMapLoading } =
        useDispatcherSearchMapContext();

    const { control: commissionControl } = useFormContext<CommissionSchema>();

    const { commissionLoadingDischarge } = useWatch({
        control: commissionControl,
    });

    const extractGroupedSearchedCarriersData = (fetchedData: DispatcherSearchResponse) => {
        const { data } = fetchedData;

        const dispatchersMap = new Map();

        data.forEach((data) => {
            if (data.reason !== null) {
                const compoundKey = `${data.reason.type}-${data.reason.locations[0].longitude}-${data.reason.locations[0].latitude}`;
                if (dispatchersMap.has(compoundKey)) {
                    dispatchersMap.get(compoundKey).wholeDispatcherData.push(data);
                    return;
                }
                dispatchersMap.set(compoundKey, { reason: data.reason, wholeDispatcherData: [data] });
            }
        });
        const groupedArray: GroupedDispatchersData[] = Array.from(dispatchersMap.values());

        return groupedArray;
    };

    const extractSearchedCarrierCounts = (fetchedData: DispatcherSearchResponse) => {
        const { counts: extractedFromResponse } = fetchedData;

        const extractedCounts: DispatcherSearchCounts = {
            counts: extractedFromResponse,
        };
        return extractedCounts;
    };

    const loadingDischargeCoordinates = useMemo(() => {
        if (!commissionLoadingDischarge) return [];

        // Convert commissionLoadingDischarge to an array of [latitude, longitude] tuples
        return commissionLoadingDischarge
            .filter((item) => isNumber(item.location?.longitude) && isNumber(item.location?.latitude))
            .map((item) => [item.location?.longitude as number, item.location?.latitude as number] as [number, number]);
    }, [commissionLoadingDischarge]);

    // route map geometry
    const geoJsonLayer: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: { name: 'Route' },
                geometry: {
                    type: 'MultiLineString',
                    coordinates: [routePoints],
                },
            },
        ],
    };

    useEffect(() => {
        const getRoutes = async () => {
            const result = await fetchRoutesQuery(loadingDischargeCoordinates);

            if (result?.routes?.[0]?.geometry?.coordinates) setRoutePoints(result.routes[0].geometry.coordinates);
            if (result?.routes?.[0]?.distance) setRouteDistance(Math.floor(result.routes[0].distance / 1000));
        };
        getRoutes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDischargeCoordinates]);

    useEffect(() => {
        if (searchedCarriers) {
            const extractedCounts = extractSearchedCarrierCounts(searchedCarriers);
            const extractedGroupedDispatchersData = extractGroupedSearchedCarriersData(searchedCarriers);

            setTransformedDispatcherCounts(extractedCounts);
            setTransformedGroupedDispatcherData(extractedGroupedDispatchersData);
        }
    }, [searchedCarriers]);

    return (
        <MapboxMap
            id="map"
            mapLib={import('mapbox-gl')}
            mapboxAccessToken={config.api.mapboxToken}
            initialViewState={{
                longitude: 14.81084786751657,
                latitude: 48.82111444207089,
                zoom: 4,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            minZoom={4}
            onZoom={(e) => {
                setMapZoomValue(e.viewState.zoom);
            }}
        >
            {routePoints.length > 0 && (
                <Source data={geoJsonLayer} type="geojson">
                    <Layer id="route" type="line" paint={{ 'line-width': 4, 'line-color': '#1770ff' }} />
                </Source>
            )}

            {/* Loading and discharge radius circle */}
            {!isDispatcherFiltrationToggled && (
                <>
                    <MapSelectionRadius
                        coordinates={loadingDischargeCoordinates[0]}
                        name={'loading'}
                        radius={onLoadingRadiusMapChange}
                        loadingOrDischargeLatitude={commissionLoadingDischarge?.[0]?.location?.latitude || 0}
                    />

                    <MapSelectionRadius
                        coordinates={loadingDischargeCoordinates[loadingDischargeCoordinates.length - 1]}
                        name={'discharge'}
                        radius={onDischargeRadiusMapChange}
                        loadingOrDischargeLatitude={commissionLoadingDischarge?.at(-1)?.location?.latitude || 0}
                    />
                </>
            )}

            {/* displaying loading/discharge location on the map */}
            {commissionLoadingDischarge?.map((loadingDischargeCoordinateItem, index) => (
                <Marker
                    key={index}
                    latitude={loadingDischargeCoordinateItem.location?.latitude || 0}
                    longitude={loadingDischargeCoordinateItem.location?.longitude || 0}
                    anchor="center"
                    pitchAlignment="map"
                >
                    <div className={styles.pointContainer}>
                        <div className={styles.loadingDischargePoint}></div>
                        <div className={styles.popup}>
                            <Typography variant="h6">{loadingDischargeCoordinateItem?.location?.city}</Typography>
                            {index === commissionLoadingDischarge.length - 1 && (
                                <Typography className={styles.distance} variant="p">
                                    {routeDistance} km
                                </Typography>
                            )}
                        </div>
                    </div>
                </Marker>
            ))}

            <MapPoints
                transformedGroupedDispatcherData={transformedGroupedDispatcherData}
                mapZoomValue={mapZoomValue}
            />

            {!isDispatcherFiltrationToggled && (
                <DispatcherSearchMapLegend
                    commissionsCount={transformedDispatcherCounts?.counts.commission}
                    dispatchersCount={transformedDispatcherCounts?.counts.dispatcher}
                    hqsCount={transformedDispatcherCounts?.counts.hq}
                />
            )}
            {isMapLoading && (
                <div className={styles.spinnerWrapper}>
                    <Spinner />
                </div>
            )}
        </MapboxMap>
    );
};
