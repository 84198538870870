import type { IconProps } from './types';

export const SortIcon: React.FC<IconProps> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1.5}
            {...props}
        >
            <g>
                <path data-direction="desc" d="M16.2741 15.8625L12.0006 20.139L7.72411 15.8625" stroke="currentColor" />
                <path
                    data-direction="asc"
                    d="M7.72588 8.13757L11.9994 3.86107L16.2759 8.13757"
                    stroke="currentColor"
                />
            </g>
        </svg>
    );
};
