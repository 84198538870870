import React, { forwardRef, useLayoutEffect, useState } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { CheckIcon, MinusIcon } from 'assets/icons';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

export interface ICheckboxProps extends RadixCheckbox.CheckboxProps {
    error?: boolean;
}

export const Checkbox = forwardRef<HTMLButtonElement, ICheckboxProps>(
    ({ checked: checkedControlled, onCheckedChange, error, ...props }, ref) => {
        // Component controls its own state if checkedControlled is not provided
        const [checked, setChecked] = useState<RadixCheckbox.CheckedState>(false);

        const handleCheckChange = (value: RadixCheckbox.CheckedState) => {
            setChecked(value);
            onCheckedChange?.(value);
        };

        useLayoutEffect(() => {
            // If checkedControlled is provided, we need to update the internal state on change
            if (checkedControlled !== undefined) {
                setChecked(checkedControlled);
            }
        }, [checkedControlled]);

        return (
            <RadixCheckbox.Root
                ref={ref}
                checked={checked}
                onCheckedChange={handleCheckChange}
                className={classNames(styles.checkbox, {
                    [styles.error]: !!error,
                })}
                {...props}
            >
                <RadixCheckbox.Indicator className={styles.indicator}>
                    {checked === true && <CheckIcon height={16} width={16} strokeWidth={2} />}
                    {checked === 'indeterminate' && <MinusIcon height={16} width={16} strokeWidth={2} />}
                </RadixCheckbox.Indicator>
            </RadixCheckbox.Root>
        );
    },
);
