import type { IconProps } from './types';

export const CheckIcon: React.FC<IconProps> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1.5}
            {...props}
        >
            <path d="M6 11.25L9.75 15.75L18.75 7.5" stroke="currentColor" />
        </svg>
    );
};
